




import { Component, Vue } from 'vue-property-decorator'
import OfficeDetail from './components/OfficeDetail.vue'

@Component({
  name: 'EditOffice',
  components: {
    OfficeDetail
  }
})
export default class extends Vue {}
