























import { Component, Vue } from 'vue-property-decorator'
import {
  errorMsg
} from '@/utils'
import {
  getEmail
} from '@/api/emails'

@Component({
  name: 'InternalMailView'
})

export default class extends Vue {
  private skeletonLoader = false
  private email: any = {}

  created() {
    if (this.$route.params.id) {
      this.getItem(this.$route.params.id)
    }
  }

  private async getItem(id: string) {
    try {
      this.skeletonLoader = true
      const { data } = await getEmail({ id })
      this.email = data
    } catch (err) {
      await errorMsg('api.entityNotFound')
    } finally {
      this.skeletonLoader = false
    }
  }
}
